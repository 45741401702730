import Head from 'next/head';
import { ReactNode } from 'react';

interface Props {
  title: string;
  favicon?: string;
  children?: ReactNode;
  description?: string;
}

export function Meta({ title, favicon, description, children }: Props) {
  return (
    <Head>
      <title>{title ?? 'Wolfia - Accelerate Security Reviews for AI-first Companies'}</title>
      {
        favicon ? (
          <link rel="icon" href={favicon} />
        ) : (
          <>
            <link rel="icon" href="/favicon.ico" sizes="any" />
            <link rel="icon" href="/images/meta/icon.svg" type="image/svg+xml" />
            <link rel="apple-touch-icon" href="/images/meta/apple-touch-icon.png" />
            <link rel="manifest" href="/site.webmanifest" />
          </>
        )
      }
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description ?? 'Wolfia helps AI companies eliminate security review bottlenecks and close deals faster with purpose-built security review automation.'} />
      {children}
    </Head>
  );
}